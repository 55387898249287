import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@/components/layout"

import BackgroundImage from "gatsby-background-image"
import Services from "@/components/services"
import SEO from "@/components/seo"

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query referenceQuery {
      back: file(relativePath: { eq: "projects/back.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avant: file(relativePath: { eq: "projects/timeline/avant.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      entre: file(relativePath: { eq: "projects/timeline/entre.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      apres: file(relativePath: { eq: "projects/timeline/apres.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arrowRight: file(relativePath: { eq: "arrow-right.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "projects" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Page two" />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: 250,
          backgroundColor: "#404543",
          filter: "brightness(0.5)",
        }}
      >
        <BackgroundImage
          style={{
            height: "100%",
            width: "100%",
            backgroundPosition: "70% 70%",
          }}
          Tag="section"
          fluid={data.back.childImageSharp.fluid}
        ></BackgroundImage>
      </div>
      <div className="col-lg-12">
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ color: "#33af5b", fontSize: 32 }}>
                Références & projets l'entreprise Tahtane Malek
              </h1>
              <h2 className="mb-5" style={{ color: "#8bc63e" }}>
                Aménagement d'espaces verts
              </h2>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="relative">
                <h3
                  className="absolute w-100 h-100 d-flex align-items-center justify-content-center timeline"
                  style={{
                    zIndex: 3,
                  }}
                >
                  Avant
                </h3>
                <BackgroundImage
                  style={{
                    height: 300,
                    width: "100%",
                    backgroundPosition: "70% 70%",
                  }}
                  Tag="section"
                  fluid={data.avant.childImageSharp.fluid}
                ></BackgroundImage>
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="relative">
                <h3
                  className="absolute w-100 h-100 d-flex align-items-center justify-content-center timeline"
                  style={{
                    zIndex: 3,
                  }}
                >
                  Travaux
                </h3>
                <BackgroundImage
                  style={{
                    height: 300,
                    width: "100%",
                    backgroundPosition: "70% 70%",
                  }}
                  Tag="section"
                  fluid={data.entre.childImageSharp.fluid}
                ></BackgroundImage>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="relative">
                <h3
                  className="absolute w-100 h-100 d-flex align-items-center justify-content-center timeline"
                  style={{
                    zIndex: 3,
                  }}
                >
                  Aprés
                </h3>
                <BackgroundImage
                  style={{
                    height: 300,
                    width: "100%",
                    backgroundPosition: "70% 70%",
                  }}
                  Tag="section"
                  fluid={data.apres.childImageSharp.fluid}
                ></BackgroundImage>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mt-5 mb-5">
                  <h3>Gallerie images projets</h3>
                </div>
                {data.allFile.edges.map(edge => {
                  return (
                    <div className="col-lg-3 mb-4">
                      <BackgroundImage
                        style={{
                          height: 200,
                          width: "100%",
                          border: "solid 1px #686b6873",
                          borderRadius: 2,
                        }}
                        Tag="section"
                        fluid={edge.node.childImageSharp.fluid}
                      ></BackgroundImage>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-5 p-0 pb-5">
        <Services />
      </div>
    </Layout>
  )
}

export default SecondPage
